import { useLocation } from '@kaliber/routing'
import { useReportError } from '/machinery/ReportError'

// TODO: Erik/Lars/Erwin - A problem with this approach is that for each page type we require the Javascript of all pages. Not a problem for the short term
import { ArticlePageDefault, ArticlePageGedicht, ArticlePageSnackables, ArticlePageMemberOffer, ArticlePageBordered } from './article-types/ArticlePage'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SearchContextProvider } from '/machinery/SearchContext'

export default function ArticlePage({ doc, regionData, issues }) {
  const queryClient = new QueryClient()

  const reportError = useReportError()
  const location = useLocation()

  const PageType = getPageToRender({ template: doc.metadata.template, reportError, location })

  const issue = doc.issue

  return (
    <SearchContextProvider {...{ doc, regionData, issue }} >
      <QueryClientProvider client={queryClient}>
        <PageType {...{ doc, regionData, issues }} />
      </QueryClientProvider>
    </SearchContextProvider>
  )
}

function getPageToRender({ template, location, reportError }) {
  switch (template) {
    case 'gedicht':
      return ArticlePageGedicht
    case 'snackables':
      return ArticlePageDefault
    case 'single_snackable':
    case 'single_snackable_content':
      return ArticlePageBordered
    case 'kaliber-member_offer':
      return ArticlePageMemberOffer
    case 'kaliber-snackables':
      return ArticlePageSnackables
    case 'research':
      return ArticlePageDefault
    case 'kunstcollectie':
      return ArticlePageDefault
    case 'member_offer':
      return ArticlePageDefault
    case 'default':
    default:
      if (template !== 'default') reportError(new Error(`Could not find template '${template}'. Error occurred on ${location.pathname}`))
      return ArticlePageDefault
  }
}
